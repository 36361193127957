import React, { useRef } from "react"
import Select, { components } from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"

import "./style.scss"
import useNewRxFlow from "../../utils/useNewRxFlow"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";

const NewCustomSelect = ({
  value,
  onChange,
  options,
  name,
  placeholder,
  error,
  components,
  isDisabled,
  isContactsSelect,
  isOneEye,
  ref,
  onMenuOpen,
  ...otherProps
}) => {
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const formatOptionLabel = ({ label, labelOneEye, labelNewRx }) => (
    <div dangerouslySetInnerHTML={{ __html: isOneEye ? labelOneEye : (isNewRx || isNewRxDesktop ? labelNewRx || label : label) }} />
  );
  const powerRef = useRef(null)

  return (
    <Select
      ref={name === "power" ? powerRef : null}
      id={name}
      name={name}
      className={`new-custom-select${error ? " error" : ""} ${isNewRxDesktop || isNewRx ? "new-rx" : ""}`}
      classNamePrefix="customSelect"
      value={value}
      onChange={onChange}
      options={options}
      formatOptionLabel={formatOptionLabel}
      placeholder={placeholder || "Select..."}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: CustomDropDownIndicator,
        ...components,
      }}
      onMenuOpen={() => {
        if (name !== "power") return
        setTimeout(() => {
          if (isNewRx || isNewRxDesktop) {
            return
          }
          powerRef.current.scrollToFocusedOptionOnUpdate = true
          powerRef.current.setState({
            focusedOption: options.find(opt => !!value ? opt.value === value : opt.value === "+2.00"),
          })
        }, 0)
      }}
      data-error={!!error}
      isDisabled={isDisabled}
      {...otherProps}
    />
  )
}

const CustomDropDownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon icon={faChevronDown} />
  </components.DropdownIndicator>
)

export default NewCustomSelect
